.source {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #929aa3;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-wrap: nowrap;
  cursor: pointer;
}

.source:hover {
  color: var(--color-purple-600);
}
